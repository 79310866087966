
import Vue from 'vue';
import { mapState } from 'vuex';
import { isExternalExpert } from '@/services/cookies';

const { VUE_APP_ENGAGEMENTADMIN_ADDRESS } = process.env;

export default Vue.extend({
  name: 'AppNavSurvey',
  data() {
    return {
      isExternalExpert: isExternalExpert.get(),
    };
  },
  computed: {
    ...mapState('survey', {
      surveyMeta: 'meta',
    }),
    isTestingMode(): boolean {
      return 'testing' in (this.$route.query || {});
    },
    title(): string {
      return (this.isTestingMode && this.$t('assessmentSurvey.itsDemoSurvey')) || this.surveyMeta?.name || '';
    },
  },
  methods: {
    onBackBtnClick() {
      if (this.$route.meta?.isStageFirstPage) {
        const url = new URL('/app/user_surveys', VUE_APP_ENGAGEMENTADMIN_ADDRESS);
        window.location.replace(url.href);
      } else {
        this.$router.go(-1);
      }
    },
  },
});
